import React, { useEffect, useState } from 'react'


function TabContainer() {

    const [twod, setTwod] = useState(null);
    const [sete, seteSet] = useState(null);
    const [value, setValue] = useState(null);
    const [serverTime, serverTimeSet] = useState(null);
    const [halfTwo, sethalfTwo] = useState(null);
    const [lastTwo, setlastTwo] = useState(null);
   
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://api.thaistock2d.com/live');
                const data = await response.json();
                setTwod(data.live.twod);
                seteSet(data.live.set);
                setValue(data.live.value);
                serverTimeSet(data.server_time);
                sethalfTwo(data.result[1].twod);
                setlastTwo(data.result[3].twod);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        // Fetch data on component mount
        fetchData();

        // Optionally, you can set up a polling mechanism to fetch data at intervals
        const interval = setInterval(fetchData, 5000);

        // Clean up interval on component unmount
        return () => clearInterval(interval);
    }, []);

    return (

        <div>
             <h4 className='text-center mt-3'>Thai 2D</h4>
                <div className='d-flex justify-content-between mx-3 rounded-3 py-2 px-4 mt-3' style={{ backgroundColor: "rgb(44, 41, 41)" }}>

                    <div style={{ height: "120px", width: "120px" , backgroundColor: "rgb(49, 48, 48)"}} className={`rounded-circle live-number d-flex justify-content-center align-items-center `}>
                        <h1 className='text-center text-primary twinkle-animation ' style={{ fontSize: "60px" }}>
                            {twod == null ? "--" : twod}
                        </h1>
                    </div>
                    <div>
                        <span className="text-primary">{serverTime}</span>
                        <div className='mt-2'>
                            <span>Set </span>
                            <div className='fs-5 '>{sete != null ? sete : '----'}</div>
                        </div>
                        <div className='mt-2'>
                            <span> Value</span>
                            <div className='fs-5 '>{value != null ? value : '----'}</div>
                        </div>
                    </div>
                </div>

                <div className='row mt-5 text-white d-flex justify-content-between mx-4'>
                    <div className='col-5 rounded-4 py-3 px-3  text-center' style={{backgroundColor:"rgb(44, 41, 41)"}}>
                        <p style={{ fontSize: "20px" }}>
                            {halfTwo == "--" ? "comming Soon" : halfTwo}
                        </p>
                        <p style={{ fontSize: "17px" }}>
                            12:01 PM
                        </p>
                    </div>
                    <div className='col-5 rounded-4 py-3 px-3  text-center' style={{backgroundColor:"rgb(44, 41, 41)"}}>
                        <p style={{ fontSize: "20px" }}>
                            {lastTwo == "--" ? "comming Soon" : lastTwo}
                        </p>
                        <p style={{ fontSize: "15px" }}>
                            4:30 PM
                        </p>
                    </div>
                </div>
        </div>
       
    )
}

export default TabContainer