import React from 'react';
import { AutoCenter } from 'antd-mobile';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Footer from './components/Footer';
import Home from './components/home/Home';
import Sport from './components/sport/Sport';
import CalendarPage from './components/calendar/CalendarPage'; // Assuming you create a wrapper component for Calendar
import './App.css'
import Marquee from './components/home/Marquee';
import Currency from './components/currency/Currency';
import logo from './asset/logoe.jpg'

const shortText = "M9 Shan Koe Mee";
const marqueeText = "စလော့ ၊ ငါးပြစ် ၊ ရှမ်းကိုးမီးနှင့် ထီထိုးဆော့ကစားရန် support မှ အပ်ပလီကေးရှင်းရယူပါ";
function App() {
  return (
    <div className="App">
      <Router>
        <div className=''>
            <AutoCenter className='d-flex'>
<div className='d-flex align-items-center'>
<img src={logo} className='rounded-circle' style={{width:"50px"}}/>
            <h3  className='text-warning py-2 fw-bolder ms-3'>{shortText}</h3>
</div>
            </AutoCenter>
           
           
       
          <Marquee text={marqueeText} />
          <Routes>
        
            <Route path="/" element={<Home />} />
            <Route path="/currency" element={<Currency />} />
            <Route path="/sport" element={<Sport />} />
            <Route path="/calendar" element={<CalendarPage />} />
            
          </Routes>
          <div className=''>
          <Footer />
          </div>
        </div>
      </Router>
    </div>
  );
}

export default App;