import React from 'react';
import { Button } from 'antd-mobile';
import { LeftOutline, RightOutline } from 'antd-mobile-icons';

const ThreeCalendarItem = ({ data, currentYear, page, handlePrevYear, handleNextYear }) => {
    const monthNames = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const getLuckyNumbersForMonth = (month) => {
        return data
            .filter(item => new Date(item.create_date).getMonth() === month)
            .sort((a, b) => a.lucky_number.localeCompare(b.lucky_number));
    };

    return (
        <div className="calendar-container">
            <div className="calendar-header">
                <div className="calendar-month-year d-flex justify-content-center">
                    <Button size='mini' style={{ backgroundColor: "rgb(44, 41, 41)", color: "yellow" }} className='rounded-circle py-2' onClick={handlePrevYear}>
                        <LeftOutline />
                    </Button>
                    <div className=''>
                        <div className='mt-2' style={{ width: "200px" }}>{currentYear}</div>
                    </div>
                    <Button size='mini' style={{ backgroundColor: "rgb(44, 41, 41)", color: "yellow" }} className='rounded-circle py-2' onClick={handleNextYear}>
                        <RightOutline />
                    </Button>
                </div>
            </div>
            <div className="calendar-2d">
                {monthNames.map((monthName, index) => (
                    <div key={index} className="calendar-day">
                        <div className="calendar-date">{monthName}</div>
                        {getLuckyNumbersForMonth(index).map(item => (
                            <div 
                                data-bs-toggle="modal" 
                                data-bs-target={`#modal-${item.id}`} 
                                key={item.id} 
                                style={{ backgroundColor: "rgb(44, 41, 41)" }} 
                                className="lucky-number"
                            >
                                <div>{item.lucky_number}</div>
                                <div 
                                    className="modal fade" 
                                    id={`modal-${item.id}`} 
                                    data-bs-backdrop="static" 
                                    data-bs-keyboard="false" 
                                    tabIndex="-1" 
                                    aria-labelledby={`modalLabel-${item.id}`} 
                                    aria-hidden="true"
                                >
                                    <div className="modal-dialog modal-dialog-centered">
                                        <div className="modal-content" style={{ backgroundColor: "rgb(44, 41, 41)" }}>
                                            <div className="modal-header">
                                                <h5 className="modal-title fs-5" id={`modalLabel-${item.id}`}>{item.create_date}</h5>
                                                <button 
                                                    type="button" 
                                                    className="btn-close bg-warning" 
                                                    style={{ color: "yellow" }} 
                                                    data-bs-dismiss="modal" 
                                                    aria-label="Close"
                                                ></button>
                                            </div>
                                            <div className="modal-body">
                                                {/* {getLuckyNumbersForMonth(index).map(modalItem => ( */}
                                                    <div className='d-flex justify-content-between px-5 mt-3' >
                                                        <div className='fs-5'>{item.section}</div>
                                                        <div className='fs-5'>{item.lucky_number}</div>
                                                    </div>
                                                {/* ))} */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ThreeCalendarItem;