import { AutoCenter } from 'antd-mobile'
import React from 'react'

function Spinner() {
  return (
    <AutoCenter>
        <div class="spinner-border text-primary" role="status">
    {/* <span class="sr-only">Loading...</span> */}
  </div>
    </AutoCenter>
  )
}

export default Spinner