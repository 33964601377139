import React from 'react'
import TabChangeContainer from './TabChangeContainer'

function CalendarPage() {
  return (
    <div>
   <TabChangeContainer/>
    </div>
  )
}

export default CalendarPage