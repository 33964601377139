import React from 'react'
import TabContainer from './TabContainer'

function Home() {
    return (
        <>

            <TabContainer />

        </>
    )
}

export default Home