import React from 'react'
import {Tabs } from 'antd-mobile'
import LuckyNumberCalendar from './LuckyNumberCalendar'
import ThreeLuckyNumber from './ThreeLuckyNumber'
function TabChangeContainer() {
    return (
        <div>
            <Tabs>
                <Tabs.Tab title='Thai 2D' key='2D'>

                    <h4 className='text-center mt-3'>Thai 2D Calendar</h4>
                    <LuckyNumberCalendar page='2D'/>
                </Tabs.Tab>
                <Tabs.Tab title='Thai 3D' key='3D'>
                    <h4 className='text-center mt-3'>Thai 3D Calendar</h4>
                    <ThreeLuckyNumber page='3D'/>
                </Tabs.Tab>
            </Tabs>
        </div>
    )
}

export default TabChangeContainer

