import React, { useEffect, useState } from 'react'
import Spinner from '../Spinner';

function Currency() {
const [currencyData, setCurrencyData] = useState([]);
const [UpdateTime,setUpdateTime]= useState('');
const [spinner , setSpinner] = useState(true)
    useEffect(() => {
       
        const fetchData = async () => {
            try {
                const response = await fetch('https://myanmar-currency-api.github.io/api/latest.json');
                const data = await response.json();
                setCurrencyData(data.data)
                setUpdateTime(data.timestamp)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        // Fetch data on component mount
        
        setTimeout(()=>{
            setSpinner(true)
            setSpinner(false)
        },[500])
        fetchData();
        
    },[])
  return (
    <>
    <h4 className='mt-3 text-center'>Currency Exchange Rate</h4>
    <div className='text-center mt-3'>
    <span>Update Date/Time</span>
   
    </div>
    {
        spinner ?  
        <div className='mt-5'>
            <Spinner/>
        </div>
        : 
       <>
        <p className='mt-2  text-success text-center'>{UpdateTime}</p>
      <div className='w-100'>
      <table className='text-center container  rounded-2'  style={{backgroundColor:"rgb(44, 41, 41)"}}>
        <thead className='py-3'>
            <tr style={{height:"40px",color:"white"}}>
                <th>Currecy</th>
                <th>Buy</th>
                <th>Sell</th>
            </tr>
        </thead>
        <tbody>
        {
        currencyData.map((e,index) => (
                <tr className='' key={index} style={{height:"33px"}}>
                    <td style={{color:"white"}}>{e.currency} <span>1</span></td>
                    <td>{e.buy} MMK</td>
                    <td>{e.sell} MMK</td>
                </tr>
        ))
        }
            
        </tbody>
    </table>
      </div>
       </>
    }
    </>
  )
}

export default Currency