import React from 'react';
import { Button } from 'antd-mobile';
import { LeftOutline ,RightOutline} from 'antd-mobile-icons';
const CalendarItem = ({ data, currentDate,page,handlePrevMonth,handleNextMonth }) => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const daysArray = Array.from({ length: daysInMonth }, (_, index) => index + 1);
  
    const getLuckyNumbersForDay = (day) => {
      return data
        .filter(item => new Date(item.create_date).getDate() === day)
        .sort((a, b) => a.lucky_number.localeCompare(b.lucky_number));
    };
  
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
  
    const weekNames = page === '2D'
      ? ['Mon', 'Tues', 'Wed', 'Thurs', 'Fri']
      : ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'];
  
    return (
      <div className="calendar-container">
        <div className="calendar-header">
          <div className="calendar-month-year d-flex justify-content-center">
          <Button size='mini' style={{backgroundColor:"rgb(44, 41, 41)",color:"yellow"}} className='rounded-circle py-2'  onClick={()=>handlePrevMonth()}>
          <LeftOutline />
          </Button>
          <div className=''>
          <div className=' mt-2' style={{width:"200px"}}>{monthNames[month]} {year}</div>
          </div>
        
          <Button size='mini' style={{backgroundColor:"rgb(44, 41, 41)",color:"yellow"}} className='rounded-circle py-2'  onClick={()=>handleNextMonth()}>
          <RightOutline />
          </Button>
            
          </div>
          <div className={`${page == '2D' ? 'calendar-week-names-2d' : 'calendar-week-names'}`}>
            {weekNames.map((weekName, index) => (
              <div key={index} className="calendar-week-name">{weekName}</div>
            ))}
          </div>
        </div>
        <div className={` ${page == '2D' ? 'calendar-2d' : 'calendar'}`}>
          {daysArray.map(day => {
            const date = new Date(year, month, day);
            const dayOfWeek = date.getDay();
            if (page === '2D' && (dayOfWeek === 0 || dayOfWeek === 6)) {
              return null; // Skip Sunday (0) and Saturday (6) for '2D'
            }
            return (
              <div key={day} className="calendar-day ">
                <div className="calendar-date">{day}</div>
                {getLuckyNumbersForDay(day).map(item => (
  <div 
    data-bs-toggle="modal" 
    data-bs-target={`#modal-${item.id}`} 
    key={item.id} 
    style={{backgroundColor:"rgb(44, 41, 41)"}} 
    className="lucky-number"
  >
    <div>{item.lucky_number}</div>
    {/* <div>{item.section}</div> */}

    <div 
      class="modal fade" 
      id={`modal-${item.id}`} 
      data-bs-backdrop="static" 
      data-bs-keyboard="false" 
      tabindex="-1" 
      aria-labelledby={`modalLabel-${item.id}`} 
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" style={{backgroundColor:"rgb(44, 41, 41)"}}>
          <div class="modal-header">
            <h5 class="modal-title fs-5" id={`modalLabel-${item.id}`}>{item.create_date}</h5>
            <button 
              type="button" 
              class="btn-close bg-warning" 
              style={{color:"yellow"}} 
              data-bs-dismiss="modal" 
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">

          {getLuckyNumbersForDay(day).map(item => (

<div className='d-flex justify-content-between px-5 mt-3' >
              <div className='fs-5'>{item.section}</div>
              <div className='fs-5'>{item.lucky_number}</div>
            </div>
          ))}
            
          </div>
        </div>
      </div>
    </div>
  </div>
))}
              </div>
            );
          })}
        </div>
      </div>
    );
};

export default CalendarItem;