import React from 'react'
import { Link } from 'react-router-dom'

function Sport() {
  return (
    <div className='container'>
        <h5 className='text-center mt-3'>
            Support
        </h5>
        <Link className='text-decoration-none mt-5' to="viber://pa?chatURI=taungpawclub" target='_blank'>

      <div className='p-3 text-center text-black mt-5 rounded-3' style={{background: "rgb(63,94,251)",
      background: "radial-gradient(circle, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%)"}}>
          Viber သိုဆက် သွယ် ရန် နှိပ် ပါ
      </div>
      </Link>


        <Link className='text-decoration-none mt-5' to="https://t.me/M9sialucky9bot" target='_blank'>

        <div className='p-3 text-center text-black mt-5 rounded-3' style={{background: "rgb(63,94,251)",
background: "radial-gradient(circle, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%)"}}>
            Telegram သိုဆက် သွယ် ရန် နှိပ် ပါ
        </div>
        </Link>



        <Link className='text-decoration-none mt-5' to="https://m.me/318606574661924" target='_blank'>

        <div className='p-3 text-center text-black mt-5 rounded-3' style={{background: "rgb(63,94,251)",
background: "radial-gradient(circle, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%)"}}>
            Messager သိုဆက် သွယ်ရန် နှိပ်ပါ
        </div>
        </Link>



        <Link className='text-decoration-none mt-5' to="https://www.facebook.com/profile.php?id=100075924802043&mibextid=ZbWKwL" target='_blank'>

        <div className='p-3 text-center text-black mt-5 rounded-3' style={{background: "rgb(63,94,251)",
background: "radial-gradient(circle, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%)"}}>
           Facebook သို ဆက် သွယ် ရန် နှိပ်ပါ
        </div>
        </Link>
    </div>
  )
}

export default Sport