import React, { useEffect, useState } from 'react';
import CalendarItem from './CalendarItem';


const LuckyNumberCalendar = ({page}) => {
    const [data, setData] = useState([]);
    const [currentDate, setCurrentDate] = useState(new Date());
  
    const getStartAndEndDates = (year, month) => {
      const startDate = new Date(year, month, 1);
      const endDate = new Date(year, month + 1, 0);
      return {
        startDate: startDate.toISOString().split('T')[0],
        endDate: endDate.toISOString().split('T')[0],
      };
    };
  
    const fetchData = async (startDate, endDate) => {
      const response = await fetch(`https://backend.sotmel.com/api/luckynumber_history/${page}?start_date=${startDate}&end_date=${endDate}`);
      const result = await response.json();
      setData(result.data);
    };
  
    useEffect(() => {
      const { startDate, endDate } = getStartAndEndDates(currentDate.getFullYear(), currentDate.getMonth());
      fetchData(startDate, endDate);
    }, [currentDate, page]);
  
    const handlePrevMonth = () => {
      setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1));
    };
  
    const handleNextMonth = () => {
      setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));
    };
  
    return (
      <div className="lucky-number-calendar">
        <div className="navigation-buttons">
         
        </div>
        <CalendarItem data={data} handlePrevMonth={()=>handlePrevMonth()} handleNextMonth={()=>handleNextMonth()} currentDate={currentDate} page={page} />
      </div>
    );
};

export default LuckyNumberCalendar;
