import React, { useEffect, useState } from 'react';
import ThreeCalendarItem from './ThreeCalendarItem';

const ThreeLuckyNumber = ({ page }) => {
    const [data, setData] = useState([]);
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

    const fetchData = async (year) => {
        const startDate = `${year}-01-01`;
        const endDate = `${year}-12-31`;
        const response = await fetch(`https://backend.sotmel.com/api/luckynumber_history/${page}?start_date=${startDate}&end_date=${endDate}`);
        const result = await response.json();
        setData(result.data);
    };

    useEffect(() => {
        fetchData(currentYear);
    }, [currentYear, page]);

    const handlePrevYear = () => {
        setCurrentYear(prevYear => prevYear - 1);
    };

    const handleNextYear = () => {
        setCurrentYear(prevYear => prevYear + 1);
    };

    return (
        <div className="lucky-number-calendar">
            <ThreeCalendarItem 
                data={data} 
                handlePrevYear={handlePrevYear} 
                handleNextYear={handleNextYear} 
                currentYear={currentYear} 
                page={page} 
            />
        </div>
    );
};

export default ThreeLuckyNumber;